<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-alert
      type="info"
      color="green darken-2"
      :value="true"
      class="mb-4"
      outline
    >
      Please tick the checkbox on the header to export all months or tick preferred months individually.
    </v-alert>

    <v-toolbar card>
      <v-select
        v-model="year"
        :items="years"
        prepend-inner-icon="event"
      />
      <v-spacer />
      <v-btn
        :href="printIncomeStatement(year, checked)"
        target="_blank"
        color="info"
      >
        Export to pdf
      </v-btn>
    </v-toolbar>
    <!-- test for checkbox group selection in vue -->
    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :loading="loading"
      :expand="expand"
      item-key="month"
      class="elevation-1"
      disable-initial-sort
      hide-actions
    >
      <template #headers="props">
        <th>
          <input
            type="checkbox"
            v-model="selectAll"
          >
        </th>
        <th
          v-for="header in props.headers"
          :key="header.text"
        >
          {{ header.text }}
        </th>
      </template>
      <template #items="props">
        <tr
          :active="props.selected"
          @click="toggleSelection(props)"
        >
          <td>
            <input
              type="checkbox"
              v-model="checked"
              :value="props.item.month"
              @input="props.item.month= $event.target.value"
            >
          </td>
          <td class="text-xs-center">
            {{ props.item.month }}
          </td>
          <td class="text-xs-center">
            {{ props.item.paymentId }}
          </td>
          <td class="text-xs-center">
            {{ props.item.method }}
          </td>
          <td class="text-xs-center">
            {{ props.item.income }}
          </td>
          <template v-if="dealerxox">
            <td class="text-xs-center">
              {{ props.item.status }}
            </td>
          </template>
        </tr>
      </template>
      <template #expand="props">
        <v-layout column>
          <v-flex xs10>
            <v-card-text color="darken-5">
              <v-data-table
                :headers="detailHeaders"
                :items="props.item.detail"
                item-key="description"
                class="elevation-1"
                disable-initial-sort
                hide-actions
              >
                <template #items="thisProps">
                  <tr>
                    <td class="text-xs-left">
                      {{ thisProps.item.description }}
                    </td>
                    <td class="text-xs-center">
                      {{ thisProps.item.amount }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-flex>
        </v-layout>
      </template>
      <template #footer>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-right">
            Total:
          </td>
          <td class="text-xs-center info">
            RM {{ totalIncome }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams, createGetUrl } from '@/rest'
import security from '@/security'

const title = 'Income'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      selected: [],
      checked: [],
      alert: false,
      alertMessage: null,
      xox: true,
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: title, disabled: true,
        },
      ],
      detailHeaders: [
        {
          text: 'Description',
          align: 'left',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Amount (RM)',
          align: 'right',
          sortable: false,
          value: 'amount',
        },
      ],
      expand: false,
      items: [],
      loading: false,
      title: title,
      totalIncome: '0.00',
      year: null,
      years: [],
      month: 'null',
    }
  },

  computed: {
    selectAll: {
      get: function () {
        return this.items ? this.checked.length === this.items.length : false
      },
      set: function (value) {
        var checked = []

        if (value) {
          this.items.forEach(function (item) {
            checked.push(item.month)
          })
        }
        this.checked = checked
      },
    },
    dealerxox: function () {
      return security.me.claimer() === 'xox' ? this.xox : !this.xox
    },
    headers: function () {
      const headers = [
        {
          text: 'Month',
          align: 'left',
          sortable: false,
          value: 'month',
        },
        {
          text: 'Payment Id',
          align: 'left',
          sortable: false,
          value: 'paymentId',
        },
        {
          text: 'Payout Method',
          align: 'left',
          sortable: false,
          value: 'method',
        },
        {
          text: 'Amount (RM)',
          align: 'right',
          sortable: false,
          value: 'income',
        },
      ]
      this.dealerxox && headers.push({
        text: 'Paid Status',
        align: 'right',
        sortable: false,
        value: 'status',
      })

      return headers
    },
  },

  watch: {
    year: function (val) {
      if (val) {
        this.getIncomeStatement(val)
        this.printIncomeStatement(val)
      }
    },
  },

  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.year = now.toFormat('yyyy')
  },
  methods: {
    getIncentiveDetails: function (month) {
      const param = { year: this.year, month }
      const callback = (element) => element.month === month
      const index = this.items.findIndex(callback)
      this.items[index].detail.length < 1 && this.$rest.get('getIncentiveDetails.php', createGetParams(param))
        .then(function (response) {
          this.items[index].detail = response.data.item
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          console.log(message)
        })
    },
    getIncomeStatement: function (year) {
      const param = { year }
      this.loading = true
      this.$rest.get('getIncomeStatement.php', createGetParams(param))
        .then(function (response) {
          this.totalIncome = response.data.totalIncome
          this.items = response.data.item
          if (this.years.length === 0) {
            const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
            const maxYear = now.toFormat('yyyy')
            for (let year = maxYear; year >= response.data.recruitmentYear; year--) {
              this.years.push(year)
            }
            this.maxDate = now.toFormat('yyyy')
          }
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
          this.loading = false
        })
    },
    toggleSelection: function (item) {
      item.selected = !item.selected
      item.expanded = !item.expanded
      if (this.selected.length > 1) this.selected.shift()
      if (item.expanded) {
        this.getIncentiveDetails(item.item.month)
      }
    },

    printIncomeStatement: function (year, checked) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/printIncome2.php?year=' + year + '&month=' + checked
        : createGetUrl('http://203.115.197.112/api/pdf/printIncome2.php?year=' + year + ('&month=' + checked))
    },

  },
}

</script>

<style scoped>
input[type=checkbox] {
    transform: scale(1.5);
}

</style>
